import React from "react";
import Axios from "../../services/Api";

//import styles from "./InventoryItem.module.css";

import { status_dictionary } from "../../helpers/statusHelper";

const itemDownload = async (record, item, file) => {
  const { data } = await Axios.DownloadHit({
    record: record,
    item: item,
  });

  // console.log(data);
  window.open(file, "_blank");
};

const openMap = (uri, location) => {
  console.log(uri);
  console.log(location);
  window.open(uri,null,"height=650,width=930,status=yes,toolbar=no,menubar=no,location="+location);
};

const InventoryItem = (props) => {
  return (
    <div className="card mb-2">
      <div className="card-header">
        <div className="d-flex flex-row justify-content-between">
          <div>
            <b>Item:</b> {props?.item?.barcode}
          </div>
          <h5>
            <span className={`badge ${status_dictionary[props?.item?.status]?.cssClass || "bg-secondary"}`}>
              {status_dictionary[props?.item?.status]?.displayText || "Desconocido"}
            </span>
          </h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-lg-10 mb-2">
            {props?.item?.location && props?.item?.location !== "null" && (
              <div>
                <b>Ubicación: </b>
                {props?.item?.location}
              </div>
            )}
            {/* {props?.item?.circulation_type && props?.item?.circulation_type !== "null" && (
              <div>
                <b>Circulación: </b>
                {props?.item?.circulation_type}
              </div>
            )} */}
            {props?.item?.collection && props?.item?.collection !== "null" && (
              <div>
                <b>Colección: </b>
                {props?.item?.collection}
              </div>
            )}
            {props?.item?.prefijo && props?.item?.prefijo !== "null" && (
              <div>
                <b>Prefijo: </b>
                {props?.item?.prefijo}
              </div>
            )}
            {props?.item?.volno && props?.item?.volno !== "null" && (
              <div>
                <b>Vol. / No.: </b>
                {props?.item?.volno}
              </div>
            )}
            {props?.item?.material_type && props?.item?.material_type !== "null" && (
              <div>
                <b>Material: </b>
                {props?.item?.material_type}
              </div>
            )}
            {props?.item?.loan_time && props?.item?.loan_time !== "null" && (
              <div>
                <b>Tiempo de préstamo: </b>
                {props?.item?.loan_time}
              </div>
            )}
            {props?.item?.hyperlink && props?.item?.hyperlink !== "null" && (
              <button
                className="btn btn-primary btn-small mt-2 col-12 col-lg-4 col-xxl-3"
                onClick={() => itemDownload(props?.record, props?.item?.barcode, props?.item?.hyperlink)}
              >
                <span className="me-2">
                  <i className="fas fa-globe-americas"></i>
                </span>
                En línea
              </button>
            )}
          </div>
          <div className="col-12 col-lg-2 d-flex flex-column justify-content-center">
            {(props?.item?.status === "D" || props?.item?.status === "P") && (
              <React.Fragment>
                <a
                  type="button"
                  className="btn btn-sm btn-outline-secondary position-relative mb-2"
                  href={props?.item?.booking_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Reservar
                </a>
                {props?.item?.booking_queue !== "0" && (
                  <div type="button" className="badge bg-secondary">
                    En cola: {props?.item?.booking_queue}
                  </div>
                )}
              </React.Fragment>
            )}
            {props?.item?.map !== "null" && (
              <button
                className="btn btn-sm btn-outline-secondary position-relative mb-2"
                onClick={() => openMap(props?.item?.map?.uri, props?.item?.map?.location)}
              >
                Ver mapa
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryItem;
