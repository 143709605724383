import React, { useEffect, useState } from "react";

import app_params from "../../config/params";
import Axios from "../../services/Api";

const Navbar = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Axios.GetMenu().then((res) => res.data)
    .then((data) => {
      if (data?.success) {
        setNavLinks(data?.links);
      }
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setNavLinks([]);
      setIsLoading(false);
    });
  }, []);

  return (
    <header>
      <nav className={`navbar navbar-expand-lg navbar-light theme-navbar`}>
        <div className="container-fluid">
          <a className="navbar-brand" href={`${app_params.basepath}`}>
            <img src={`${app_params.basepath}/assets/logos/brand.png`} alt="brand-logo" className="d-inline-block align-middle brand-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse w-100" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-lg-2 mt-3 mt-lg-0">
              <li className="nav-item mx-2 px-2 px-lg-0">
                <a className="nav-link" href="/u" tabIndex="-1">
                  Estatus en biblioteca
                </a>
              </li>
              {isLoading && (
                <li>
                  <i className="fa fa-spinner fa-spin"></i>
                </li>
              )}
              {!isLoading &&
                navLinks.map((link, index) => (
                  <li key={index} className="nav-item mx-2 px-2 px-lg-0">
                    <a className="nav-link" href={link?.href} target="_blank" rel="noreferrer" tabIndex="-1">
                      {link?.label}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
