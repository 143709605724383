import React, { useState, useEffect } from "react";

export default function SeeMoreTags(props) {
  const [tag_value, setTagVal] = useState([]);
  const [toggleDetails, setToggleDetails] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      if (props.etiqueta) {
        setLoaded(true);
        setTagVal(props.etiqueta);
        console.log(props.etiqueta);
      }
    }
  }, [loaded, props.etiqueta]);

  const detailsHelper = {
    true: `Ver más <i class="fas fa-angle-down"></i>`,
    false: `Ver menos <i class="fas fa-angle-up"></i>`,
  };

  const validateSize = () => {
    if (tag_value[0]?.tag === "505") {
      return 1;
    } else {
      return 3;
    }
  };

  return (
    <div>
      {tag_value.map((tag_val, index) => {
        if (index < validateSize()) {
          return (
            <div key={index} className="m-0 p-0 text-break">
              {tag_val?.searchargs === "NULL" && tag_val?.asHtml === "false" && (
                <React.Fragment>
                  {tag_val.prefijo !== "NULL" && tag_val.prefijo + " - "}
                  {tag_val?.subfields && !Array.isArray(tag_val?.subfields)
                    ? tag_val.subfields.value
                    : tag_val.subfields
                        ?.map((subfield) => subfield.value)
                        .join(" ")}
                </React.Fragment>
              )}
              {index === validateSize() - 1 && (
                <div
                  className="btn btn-light btn-sm btnVerMas"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#col" + tag_value[0]?.tag}
                  aria-expanded="false"
                  aria-controls={"col" + tag_value[0]?.tag}
                  dangerouslySetInnerHTML={{
                    __html: detailsHelper[toggleDetails],
                  }}
                  onClick={() => setToggleDetails(!toggleDetails)}
                />
              )}
            </div>
          );
        }
      })}
      <div className="collapse" id={"col" + tag_value[0]?.tag}>
        {tag_value.map((tag_val, index) => {
          if (index >= validateSize()) {
            return (
              <div key={index} className="m-0 p-0 text-break">
                {tag_val?.searchargs === "NULL" && tag_val?.asHtml === "false" && (
                  <React.Fragment>
                    {tag_val.prefijo !== "NULL" && tag_val.prefijo + " - "}
                    {tag_val?.subfields && !Array.isArray(tag_val?.subfields)
                      ? tag_val.subfields.value
                      : tag_val.subfields
                          ?.map((subfield) => subfield.value)
                          .join(" ")}
                  </React.Fragment>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
